<template>
  <v-container
    id="formations"
    fluid
    tag="section"
  >
    <h1 class="font-weight-light mb-2 headline text-center">
      Factures en
      <v-select
        class="year-selector"
        v-model="annee"
        :items="years"
        @input="goToYear()"
      />
    </h1>

    <base-material-card
      color="primary"
      class="px-5 py-3"
    >
      <!--
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher une facture"
        single-line
        hide-details
      />
      -->
      <div class="py-2" />
      <template v-slot:heading>
        <v-tabs
          v-model="tabs"
          background-color="transparent"
          slider-color="white"
        >
          <v-tab
            v-for="(month, index) in months"
            :key="index"
            :to="`#${index+1}`"
          >
            {{ month }}
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items
        v-model="tabs"
        class="transparent"
      >
        <v-tab-item
          v-for="i in 12"
          :key="i"
          :value="`${i}`"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-data-table
                class="table-factures"
                :headers="headers"
                :items="factures.filter((f) => new Date(f.dateEmission).getMonth()+1 == i)"
                :search="search"
                :sort-by="'dateEmission'"
                :sort-desc="false"
                @click:row="rowClick"
              >
                <template v-slot:[`item.reference`]="{ item }">
                  {{ item.reference }}
                  <v-tooltip
                    bottom
                    v-if="item.etat === 'ENVOYE' && new Date().toISOString() > item.dateEcheance"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1"
                        color="#ebc538"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Échéance dépassée</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.etat`]="{ item }">
                  <div v-html="displayEtat(item.etat)"></div>
                </template>
                <template v-slot:[`item.dateEmission`]="{ item }">
                  {{ new Date(item.dateEmission).toLocaleString().substring(0, 5) }}
                </template>
                <template v-slot:[`item.dateEnvoi`]="{ item }">
                  {{ item.dateEnvoi ? new Date(item.dateEnvoi).toLocaleString().substring(0, 5) : '' }}
                </template>
                <template v-slot:[`item.montantHT`]="{ item }">
                  <b>{{ toEuro(item.montantHT) }}</b>
                </template>
                <template v-slot:[`item.montantTVA`]="{ item }">
                  <i>{{ toEuro(item.montantTVA) }}</i>
                </template>
                <template v-slot:[`item.montantTotal`]="{ item }">
                  {{ toEuro(item.montantTotal) }}
                </template>
                <template v-slot:[`item.lienFacture`]="{ item }">
                  <v-icon
                    @click.stop="downloadFacture(item.id)"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <template v-slot:body.append>
                  <tr class="ligne-total">
                    <th colspan="6">Total {{ months[i-1].toLowerCase() }} {{ annee }}</th>
                    <th>{{ toEuro(calculTotalHT(i)) }}</th>
                    <th><i>{{ toEuro(calculTotalTVA(i)) }}</i></th>
                    <th>{{ toEuro(calculTotalTTC(i)) }}</th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>

    <div
      class="d-flex justify-end mb-3"
    >
      <v-btn
        color="primary"
        x-large
        :to="{ name: 'ImporterFacture'}"
      >
        <v-icon class="mr-2">mdi-upload</v-icon>
        Importer une facture
      </v-btn>
      <!--
      <v-btn
        color="primary"
        elevation="2"
        x-large
        disabled
      >
        Créer une facture AIF
      </v-btn>
      -->
      <v-btn
        color="primary"
        x-large
        :to="{ name: 'CreerFacture'}"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Créer une nouvelle facture
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        search: '',
        annee: '',
        mois: 1,
        tabs: '' + (new Date().getMonth() + 1),
        tauxTVA: 0,
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        years: [],
        headers: [
          {
            sortable: true,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: true,
            text: 'Client',
            value: 'client.nomCommercial',
          },
          {
            sortable: true,
            text: 'Destinataire',
            value: 'destinataire.nomCommercial',
          },
          {
            sortable: true,
            text: 'Date émission',
            value: 'dateEmission',
          },
          {
            sortable: true,
            text: 'Date envoi',
            value: 'dateEnvoi',
          },
          {
            sortable: false,
            text: 'État',
            value: 'etat',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Montant HT',
            value: 'montantHT',
            align: 'right',
          },
          {
            sortable: false,
            text: 'TVA',
            value: 'montantTVA',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Total TTC',
            value: 'montantTotal',
            align: 'right',
          },
        ],
        factures: [],
        anneePremiereFacture: new Date().getFullYear(),
      }
    },
    created () {
      this.annee = this.$route.params.year;
      if (this.annee === '' + new Date().getFullYear()) {
        this.mois = this.$route.hash !== '' ? this.$route.hash.slice(1) : new Date().getMonth() + 1;
      } else {
        this.mois = this.$route.hash !== '' ? this.$route.hash.slice(1) : 1;
      }

      this.axios.get('/facturesSortantes/anneePremiere')
        .then((res) => {
          if (res.data !== '') {
            this.anneePremiereFacture = res.data;
          }
          this.years = this.allFromThisYearTo(this.anneePremiereFacture);
          this.getInvoicesFromYearAndMonth();
        });
    },

    methods: {

      rowClick(item, row) {
        this.$router.push({ name: 'FactureDetail', params: { id: item.id } });
      },

      goToYear() {
        this.$router.push({ path: '/factures/annee/' + this.annee, hash: '#1' });
      },

      getInvoicesFromYear() {
        this.axios.get('/facturesSortantes/annee/' + this.annee)
          .then((res) => {
            this.factures = [...res.data];
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération des factures', type: 'warn' });
          });
      },

      getInvoicesFromYearAndMonth() {
        this.axios.get('/facturesSortantes/annee/' + this.annee + '/mois/' + this.mois)
          .then((res) => {
            this.factures = [...res.data];
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération des factures', type: 'warn' });
          });
      },

      displayEtat(etat) {
        switch (etat) {
          case 'NOUVEAU':
            return '<span class="badge-chips-small badge-gray">En att. d\'envoi</span>';
          case 'ENVOYE':
            return '<span class="badge-chips-small badge-blue">En attente</span>';
          case 'ACQUITTE':
            return '<span class="badge-chips-small badge-green">Payée</span>';
          case 'RETARD':
            return '<span class="badge-chips-small badge-orange">En retard</span>';
        }
      },

      downloadFacture(id) {
        this.axios.get('/facturesSortantes/' + id + '/downloadPDF', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', id + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadEmargementDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la facture', type: 'warn' });
            this.downloadEmargementDisabled = false;
          });
      },

      calculTotalHT(i) {
        const facturesThisMonth = this.factures.filter((f) => new Date(f.dateEmission).getMonth() + 1 === parseInt(i));
        let totalHT = 0;
        facturesThisMonth.forEach(facture => {
          for (const key in facture) {
            if (key === 'montantHT') {
              totalHT += facture[key];
            }
          }
        });
        return totalHT;
      },

      calculTotalTVA(i) {
        const facturesThisMonth = this.factures.filter((f) => new Date(f.dateEmission).getMonth() + 1 === parseInt(i));
        let totalTVA = 0;
        facturesThisMonth.forEach(facture => {
          for (const key in facture) {
            if (key === 'montantTVA') {
              totalTVA += facture[key];
            }
          }
        });
        return totalTVA;
      },

      calculTotalTTC(i) {
        const facturesThisMonth = this.factures.filter((f) => new Date(f.dateEmission).getMonth() + 1 === parseInt(i));
        let totalTTC = 0;
        facturesThisMonth.forEach(facture => {
          for (const key in facture) {
            if (key === 'montantTotal') {
              totalTTC += facture[key];
            }
          }
        });
        return totalTTC;
      },

      allFromThisYearTo(lastYear) {
        const res = [];
        let year = new Date().getFullYear();
        while (lastYear <= year) {
          res.push(String(year));
          year--;
        }
        return res;
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),

    },

    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.mois = 1;
          this.annee = this.$route.params.year;
        } else if (to.path === from.path && to.hash !== from.hash) {
          if (this.annee === '' + new Date().getFullYear()) {
            this.mois = this.$route.hash !== '' ? this.$route.hash.slice(1) : new Date().getMonth() + 1;
          } else {
            this.mois = this.$route.hash !== '' ? this.$route.hash.slice(1) : 1;
          }
        }
        this.getInvoicesFromYearAndMonth();
      },
    },

  }
</script>

<style scoped>
  .table-factures >>> tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .table-factures >>> tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .table-factures >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: bold;
    white-space: nowrap;
  }

  .table-factures >>> .badge-gray {
    background-color: #a6a8ae26;
    color: #63646a;
  }

  .table-factures >>> .badge-blue {
    background-color: #1967c026;
    color: #135095;
  }

  .table-factures >>> .badge-green {
    background-color: #00968826;
    color: #02643f;
  }

  .table-factures >>> .badge-orange {
    background-color: #e88f1326;
    color: #905709;
  }

  .table-factures >>> .ligne-total th {
    font-size: 1.1rem;
    background: #5d3fd326;
  }

  .no-underline {
    text-decoration: none;
  }

  .year-selector {
    font-size: 1em;
    display: inline-block;
    margin-left: 0.1em;
    width: 3.6em;
  }

  .year-selector >>> .v-select__selection--comma {
    overflow: unset;
  }
</style>
